import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      HWGraphQL {
        page(id: "cG9zdDo2OQ==") {
          acfAbout {
            slike {
              cetrtaSlika {
                sourceUrl
              }
              drugaSlika {
                sourceUrl
              }
              prvaSlika {
                sourceUrl
              }
              tretjaSlika {
                sourceUrl
              }
            }
          }
        }
        infoPage: page(id: "cG9zdDozMA==") {
          acfMeta {
            informacije {
              delovnicasdnevi
              delovnicasura
              telefonska
              naslov
              email
            }
          }
        }
      }
    }
  `)
  const {
    HWGraphQL: {
      page: { acfAbout },
      infoPage: {
        acfMeta: { informacije },
      },
    },
  } = data

  return (
    <Layout>
      <SEO title="Kontakt" description="Najdete nas - Gostilna in picerija Jaka, Bolkova 25, Homec - 1235 Radomlje. Dosegljivi smo na 059 95 11 62 ter 041 212 525." />

      <section className="contact pt-120 pb-120">
        <h2
          style={{
            textAlign: "center",
            marginTop: "-2rem",
            marginBottom: "2rem",
          }}
        >
          KJE NAS NAJDETE
        </h2>
        <iframe
          width="100%"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=picerija%20jaka&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
        <div className="container" style={{ marginTop: "5rem" }}>
          <div className="row">
            <div className="col-md-10 offset-md-1 mb-60 text-center">
              <h6 className="sub-title">Picerija Jaka</h6>
              <h4 className="title">Kontaktirajte nas</h4>
            </div>
            <div className="col-md-10 offset-md-1">
              <div className="contact-info">
                <div className="row">
                  <div className="col-md-4">
                    <div className="item">
                      <div className="cont">
                        <h5>KONTAKT</h5>
                        <p dangerouslySetInnerHTML={{__html: informacije.naslov }}></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="item">
                      <div className="cont">
                        <h5>Telefon in e-pošta</h5>
                        <p dangerouslySetInnerHTML={{__html: informacije.telefonska }}></p>
                        <p>{informacije.email}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="item">
                      <div className="cont">
                        <h5>Odpiralni čas</h5>
                        <p dangerouslySetInnerHTML={{__html: informacije.delovnicasura }}></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
